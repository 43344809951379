import React, {Component} from "react";

import Payment from "../../../../Components/ProfileComponents/Payment";

import {SubscriptionCard, SubscriptionCardContainer as Container} from "../SubscriptionCard";
import IsLoading from "../../../ProfileContainer/Abonements/Loading";
import isEmpty from "lodash";
import UIStore from "../../../../Stores/UIStore";

import {observable} from "mobx";
import {observer} from "mobx-react";
import userStore from "../../../../Stores/UserStore";
import SubscriptionConstructorStore from "../../../../Stores/SubscriptionConstructorStore";
import {Typography} from "@material-ui/core";

@observer
class SubscriptionsContainer extends Component {
    @observable
    subscriptionCode = "";

    @observable
    cost = "";

    @observable
    subscriptionPermit = "";

    @observable
    subscriptionPermitType = "";

    componentDidMount() {
        //AbonementStore.loadSubscriptionsIfNeeded();
    }

    UNSAFE_componentWillReceiveProps() {
        //AbonementStore.loadSubscriptionsIfNeeded();
    }

    setPaymentData(data) {
        this.cost = data.cost;
        this.subscriptionCode = data.parkingPermitCode;
        this.subscriptionPermit = data.parkingPermit;
        this.subscriptionPermitType = data.parkingPermitType;
        this.handleBuySubscription();
    }

    resetPaymentData() {
        this.cost = "";
        this.subscriptionCode = "";
        this.subscriptionPermit = "";
        this.subscriptionPermitType = "";
    }

    getPaymentData() {
        return {
            codeSubscription: this.subscriptionCode,
            typeSubscription: this.subscriptionPermitType,
            selectedTs: this.props.selectedTs,
            cost: this.cost
        };
    }

    handleAbonementCardClick = paymentData => {
        this.setPaymentData(paymentData);
    };

    handleModalExit() {
        this.resetPaymentData();
        UIStore.closeSubscriptionModal();
    }

    handleBuySubscription() {
        UIStore.openSubscriptionModal();
    }

    renderCards() {
        const {t} = this.props;
        if (SubscriptionConstructorStore.selectedSubscriptions.length >0)
            return SubscriptionConstructorStore.selectedSubscriptions.map((element, key) => (
                /* todo: проперти zones={element.zone} больше не испольтзуется, необходимо его выпилить */
                <SubscriptionCard
                    parkingPermit={element.permissionKind}
                    parkingPermitCode={element.permissionKindCode}
                    parkingPermitType={element.permissionType}
                    parkingPermitDesc={element.permissionKind}
                    cost={element.price}
                    zones={element.zones}
                    onClick={this.handleAbonementCardClick}
                    key={key}
                />
            ));

        return <div style={{margin: '2rem auto',textAlign: 'center' }}>
            <Typography variant="subtitle1">Нет подходящих абонементов измените параметры и нажмите "далее"</Typography>
        </div>
    }

    render() {
        return (
            <>
                <>
                    {!userStore.profile.isLoading ? (
                        <Payment
                            isOpen={UIStore.modals.subscriptionModal}
                            onSubmit={UIStore.closeSubscriptionModal}
                            onExit={UIStore.closeSubscriptionModal}
                            data={{...this.getPaymentData()}}
                            actionType="subscription"
                        />
                    ) : null}
                    <IsLoading isLoading={SubscriptionConstructorStore.selectSubscriptionsStatus.isLoading}>
                        <Container empty={SubscriptionConstructorStore.selectedSubscriptions && SubscriptionConstructorStore.selectedSubscriptions.length === 0}>
                            {this.renderCards()}
                        </Container>
                    </IsLoading>
                </>
            </>);
    }
}

export default SubscriptionsContainer;
