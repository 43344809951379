import React, {useEffect, useRef, useState} from "react";
import Select from "@material-ui/core/Select";
import {
    Checkbox,
    Chip,
    Grid,
    IconButton,
    Button,
    Input,
    ListItemText,
    makeStyles,
    MuiThemeProvider,
    ListItemSecondaryAction
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Theme from "../../../../Config/theme";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faTimes from "@fortawesome/fontawesome-free-solid/faTimes";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import faStar from "@fortawesome/fontawesome-free-solid/faStar";

const SubscriptionConstructorParams = ({
                                           disabled = false,
                                           timings = [],
                                           zones = [],
                                           vehicles = [],
                                           onSearch = (timing, zone, vehicle, startDate) => {
                                           },
                                       }) => {


    const [timing, setTiming] = useState(null);
    const [zone, setZone] = useState([]);
    const [vehicle, setVehicle] = useState('');
    const defaultDate = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
    const [startDate, setStartDate] = useState(defaultDate);

    const [zoneOpen, setZoneOpen] = useState(false);
    const [errors, setErrors] = useState({zone: false, vehicle: false, startDate: false, timing: false});

    const isValid = () => {
        for (const e of Object.keys(errors)) {
            if (errors[e]) {
                return false;
            }
        }
        return true;
    }

    const validateSearchParams = () => {
        const nextErrors = {...errors};
        nextErrors.zone = isEmpty(zone)
        nextErrors.vehicle = isEmpty(vehicle);
        nextErrors.startDate = moment(startDate).isBefore(moment({}), 'day');
        setErrors(nextErrors);
    }

    useEffect(() => {
        setTiming(timings[0]);
    }, [timings]);

    useEffect(() => {
        validateSearchParams();
    }, [timing, zone, vehicle, startDate]);

    const handleZoneOpen = () => {
        setZoneOpen(true);
    };

    const handleZoneClose = () => {
        setZoneOpen(false);
    };


    const getVehicleOptions = () => {
        return vehicles.map((vehicle) => {
            return {
                label: vehicle,
                value: vehicle
            }
        })
    }

    const getTimingOptions = () => {
        return timings.map((t) => {
            return {
                label: `${t} дней`,
                value: t
            }
        })
    }

    const onChangeVehicle = (event) => {
        setVehicle(event.target.value);
    }
    const onChangeZone = (event) => {
        setZone(prev => {
            return event.target.value.filter(z => !!z);
        });
    }

    const onChangeTiming = (event) => {
        setTiming(event.target.value);
    }

    const onChangeStartDate = (event) => {
        setStartDate(event.target.value);
    }

    const handleSearch = () => {
        if (isValid()) {
            onSearch(timing, zone, vehicle, startDate);
        }
    }

    return (
        <MuiThemeProvider theme={Theme}>

            <Grid container spacing={4}>
                <Grid item xs={12} className='info-field'>
                    <div className="name">{'Транспортное средство'}</div>
                    <div className="value">
                        <Select
                            error={errors.vehicle}
                            disabled={disabled}
                            fullWidth
                            value={vehicle}
                            onChange={onChangeVehicle}
                            input={<Input/>}
                            renderValue={(selected) => (
                                <div>
                                    {selected}
                                </div>
                            )}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 300,
                                    },
                                },
                            }}
                        >
                            {getVehicleOptions().map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    <ListItemText primary={item.label}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </Grid>

                <Grid item xs={12} className='info-field'>
                    <div className="name">{'Зона'}</div>
                    <div className="value">
                        <Select
                            error={errors.zone}
                            disabled={disabled}
                            fullWidth
                            multiple
                            onOpen={handleZoneOpen}
                            onClose={handleZoneClose}
                            open={zoneOpen}
                            onChange={onChangeZone}
                            input={<Input/>}
                            renderValue={(selected) => (
                                <div>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} color="primary" style={{marginRight: 4}}/>
                                    ))}
                                </div>
                            )}
                            value={zone}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 600,
                                        paddingTop: 25,
                                        marginTop: 40,
                                        overflowY: 'auto',
                                    },
                                },
                                getContentAnchorEl: null,
                            }}

                        >
                            <IconButton size="small"
                                        style={{position: 'absolute', top: -16, right: 8, outline: 'none'}}
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleZoneClose();
                                        }}
                            >
                                <FontAwesomeIcon icon={faTimes} size="xs"/>
                            </IconButton>
                            {zones.map((z) => (
                                <MenuItem key={`zmi-${z.code}`} value={z.code}>
                                    <Checkbox checked={zone.indexOf(z.code) > -1} />
                                    <ListItemText primary={z.code}/>
                                    {z.special &&
                                        <ListItemSecondaryAction>
                                            <FontAwesomeIcon icon={faStar} size="xs"/>
                                        </ListItemSecondaryAction>
                                    }
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                </Grid>

                <Grid item xs={6} className='info-field'>
                    <div className="name">{'Дата начала действия'}</div>
                    <div className="value">
                        <TextField
                            error={errors.startDate}
                            disabled={disabled}
                            fullWidth
                            id="startDate"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{style: {margin: 7}}}
                            value={startDate}
                            onChange={onChangeStartDate}
                        />
                    </div>
                </Grid>

                <Grid item xs={6} className='info-field'>
                    <div className="name">{'Срок действия абонемента'}</div>
                    <div className="value">
                        <Select
                            error={errors.timing}
                            disabled={disabled}
                            fullWidth
                            value={timing ? timing : ''}
                            onChange={onChangeTiming}
                        >
                            {getTimingOptions().map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    <ListItemText primary={item.label}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={disabled || !isValid()}
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                    >
                        {'далее'}
                    </Button>
                </Grid>
            </Grid>
        </MuiThemeProvider>
    );
}

export default SubscriptionConstructorParams;